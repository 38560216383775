<template>
    <div>
        <CyberObserverLanding :language = "dominioactual" />
    </div>
</template>

<script>
import CyberObserverLanding from './CyberObserverLanding.vue'

export default {
  components: {
    CyberObserverLanding
  },
  data () {
    return {
      dominioactual: navigator.language
    }
  },
  created () {
    this.track()
  },
  methods: {
    track () {
      this.$gtag.pageview({ page_path: '/solutions/cybersecurity/cyberobserver' })
    }
  }
}
</script>

<style>

</style>
